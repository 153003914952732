import React from 'react';
import './Hero.css';
import Header from '../Header/Header';
import hero_image from"../../assets/hero_image.png";
import hero_image_back from"../../assets/hero_image_back.png";
import heart from"../../assets/heart.png";
import calories from"../../assets/calories.png";
import {motion} from 'framer-motion';
import NumberCounter from 'number-counter';
const Hero = () => {
  const transition = {type: 'spring',duration:4}
  const mobile = window.innerWidth<=768?true:false;
  return (
    <div className="hero" id='home'>
         
      {/* left side */}
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />

        {/* The best ad */}

        <div className="the-best-ad">
          <motion.div
          initial={{left:mobile?"178px":'238px'}}
          whileInView={{left:'8px'}}
          transition={{...transition,type:'tween'}}
          
          ></motion.div>
          <span>The Best Fitness Club In Town...</span>
        </div>

        {/* the hero heading */}

        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>your</span>
          </div>
          <div>
            <span>Ideal body</span>
          </div>
          <div className="t1">
            <span>
            In here we will help you to shape and build your ideal body and live up your life to fullest

            </span>
          </div>

          

          
        </div>

        {/* figures */}
        <div className="figures">
            <div>
              <span><NumberCounter end={140} start={50} delay='4' preFix="+"/> </span>
              <span>Expert coaches</span>
            </div>
            <div>
              <span><NumberCounter end={879} start={700} delay='4' preFix="+"/></span>
              <span>members joined</span>
            </div>
            <div>
              <span><NumberCounter end={80} start={0} delay='4' preFix="+"/></span>
              <span>Fitness PROGRAMS</span>
            </div>
          </div>


          {/* Hero Buttons */}
      <div className="hero-buttons">
        <button className="btn">Get Started</button>
        <button className="btn">Learn More</button>
      </div>

      </div>

      <div className="right-h">
        <button className="btn">Join Now</button>


        <motion.div 
        initial={{right:'-1rem'}}
        whileInView={{right:'4rem'}}
        transition={transition} className="heart-rate">
          <img src={heart} alt="heart"/>
          <span>Heart Rate</span><span>116 bpm</span>
        </motion.div>
        
        {/* hero images */}

        <img src={hero_image} alt="" className='hero_image'/>
        <motion.img
        initial={{right:'11rem'}}
        whileInView={{right:'20rem'}}
        transition={transition}
        src={hero_image_back} alt="" className='hero_image_back'/>



        {/* calories */}

        <motion.div 
        initial={{right:'37rem'}}
        whileInView={{right:'28rem'}}
        transition={transition}
        className="calories">
          <img src={calories} alt="" />
          <div>
          <span>calories burned</span>
          <span>200 kcal</span>
          </div>
          
        </motion.div>



      </div>
    </div>
  );
}

export default Hero
