import React , {useState} from 'react';
import {Link} from "react-scroll";
import "./Header.css";
import Logo from '../../assets/logo.png';
import Bars from '../../assets/bars.png';


const Header = () => {
  const mobile = window.innerWidth<=768?true:false;
  const [menuOpened,setMenuOpened] =useState(false);
  return (
    <div className="header">
      <img src={Logo} alt="" className="logo" />

      {menuOpened === false && mobile === true ? (
        <div
          onClick={() => setMenuOpened(true)}
          style={{
            backgroundColor: "var(--appColor)",
            padding: "0.5rem",
            borderRadius: "5px",
          }}
        >
          <img src={Bars} alt="" style={{ width: "1.5rem" }} />
        </div>
      ) : (
        <ul className="header-menu">
          <li>
            <Link onClick={() => setMenuOpened(false)} to="home" spy={true} smooth={true} activeClass='active'>
              HOME
            </Link>
          </li>
          <li>
            <Link onClick={() => setMenuOpened(false)} to="programs" spy={true} smooth={true}>
              PROGRAMS
            </Link>
          </li>
          <li>
            <Link onClick={() => setMenuOpened(false)} to="reasons" spy={true} smooth={true}>
              WHY-US
            </Link>
          </li>
          <li>
            <Link onClick={() => setMenuOpened(false)} to='plans' spy={true} smooth={true}>
              PLANS
            </Link>
          </li>
          <li>
            <Link onClick={() => setMenuOpened(false)} to="testimonials" spy={true} smooth={true}>
              TESTIMONIALS
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
}

export default Header
