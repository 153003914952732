import React from "react";
import "./Plans.css";
import { plansData } from "../../data/plansData";
import whiteTick from "../../assets/whiteTick.png";

const Plans = () => {
  return (
    <div className="plans-container">
      <div className="blur plans-blur-1"></div>
      <div className="blur plans-blur-2"></div>
      <div className="programs-header" style={{ gap: "2rem" }}>
        <span className="stroke-text">READY TO START</span>
        <span>YOUR JOURNEY</span>
        <span className="stroke-text">NOW WITH-US</span>
      </div>

      {/* plans card */}

      <div className="plans">
        {plansData.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span className="name1">{plan.name}</span>
            <span className="name1">$ {plan.price}</span>

            <div className="features">
              {plan.features.map((feature, i) => (
                <div className="feature">
                  <div className="feature">
                    <img src={whiteTick} alt=""  className="tick"/>
                    <span key={i} className="f1">{feature}</span>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <span className="seeben">see morer benifits-> </span>
            </div>
            <button className="btn">join now</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
